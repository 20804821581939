@media (max-width: 575.98px) {
    .container-1200,
    .container-1400,
    .container-1080 {
        max-width: 540px !important;
        margin: auto;
    }
}
@media (max-width: 767.98px) {
    .container-1200,
    .container-1400,
    .container-1080,
    .container-1000 {
        max-width: 720px !important;
        margin: auto;
    }
}
@media (max-width: 991.98px) {
    .container_fluid,
    .container-1200,
    .container-1400,
    .container-1080,
    .container-1000 {
        max-width: 960px !important;
        margin: auto;
    }
}

@media (max-width: 812px) {
    .container_fluid,
    .container-1200,
    .container-1400,
    .container-1080,
    .container-1000 {
        max-width: 960px !important;
        margin: auto;
        padding: 0px 15px;
    }

    #header .logo img {
        max-height: 30px;
        box-shadow: none;
    }

    #header .logo h4 {
        font: normal normal normal 12px/12px Helvetica Neue;
        letter-spacing: 0.6px;
        color: #333333;
    }

    .nav_row {
        flex-wrap: unset;
    }

    .mobile-nav-toggle.bi-x {
        color: #000;
        font-size: 50px;
    }

    .mobile-nav-toggle {
        color: #333333;
    }

    .navbar-mobile ul {
        display: inline-grid;
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 10px 0;
        background-color: #fff;
        overflow-y: auto;
        transition: 0.3s;
        padding: 50px 0px;
        text-align: center;
        align-content: center;
        justify-content: center;
    }

    .navbar-mobile a {
        font-size: 18px;
        color: #333333;
    }

    .navbar li:hover:after {
        width: 0%;
    }

    .navbar-mobile .dropdown ul a {
        font-size: 18px;
    }

    .navbar-mobile .dropdown ul {
        position: static;
        display: none;
        margin: 0px 0px;
        padding: 10px 0;
        z-index: 99;
        opacity: 1;
        visibility: visible;
        background: transparent;
        box-shadow: none;
    }

    .navbar-mobile .dropdown ul a {
        padding: 10px 0px;
    }

    .navbar-mobile a:hover,
    .navbar-mobile .active,
    .navbar-mobile li:hover > a {
        color: #ff6600;
    }

    .menu_member {
        background-color: #ff934c;
    }

    .menu_member h4 {
        font-size: 20px;
    }

    .menu_member a,
    .menu_member h4 {
        color: #fff !important;
    }

    /* #myVideo {
        width: 100vh !important;
    } */

    .banner_info_wrap {
        margin: auto;
        position: absolute;
        width: 335px;
        height: 296px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .banner_info {
        margin: 50px 0px 50px 10px;
    }

    .banner_info h1 {
        font-size: 22px;
    }

    .banner_info p {
        font-size: 16px;
    }

    /* .banner-container-inner img,
    .philosophy .banner-container-inner,
    .philosophy .banner-container-inner img {
        height: 360px;
    } */

    .banner-title h1 {
        font-size: 24px;
        letter-spacing: 1.2px;
        color: #ffffff;
    }

    .banner-container-inner .banner-title {
        font-size: 24px;
        letter-spacing: 1.2px;
        color: #ffffff;
        position: absolute;
        bottom: -10%;
        left: 25%;
        transform: translate(-50%, 40%);
        z-index: 2;
    }

    #footer .logo-wrap {
        display: block;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 18px;
        text-align: center;
    }

    #footer .logo-img {
        max-width: 90px;
    }

    #footer .footer-top {
        padding: 58px 20px 5px 20px;
    }

    #footer .copyright p {
        font: normal normal normal 12px/14px Helvetica Neue;
        letter-spacing: 0.6px;
        color: #333333;
        margin-bottom: 0;
        font-size: 12px;
    }

    .footer-nav-mobile {
        display: block !important;
        /* font-size: 28px; */
        margin-top: 50px !important;
    }

    #footer a,
    #footer a:focus {
        font-size: 18px;
        font-weight: 700;
        line-height: 2.5em;
    }

    #footer .copyright {
        text-align: left;
        padding: 10px 0;
        margin-top: 50px;
    }

    #footer ul {
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: center;
        justify-content: center;
    }

    /*--------------------------------------------------------------
  # Home / Content Misc
--------------------------------------------------------------*/

    .title {
        margin: 53px 0 30px 0;
    }

    .title h2 {
        font-size: 24px;
        letter-spacing: 2.4px;
        color: #333333;
    }

    .title h3 {
        font-size: 21px;
        letter-spacing: 4.2px;
        color: #333333;
    }

    .title span {
        font-size: 8px;
        letter-spacing: 0.8px;
        color: #999999;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .title p {
        font-size: 16px;
        letter-spacing: 1.6px;
        color: #333333;
    }

    .home-container img {
        padding: 50px;
    }

    .home-service-container .service-wrap .service-title h2 {
        font-size: 21px;
    }

    .home-service-container .service-wrap .service-title p {
        font-size: 18px;
    }

    .home-service-container .service-wrap .service-title {
        margin-bottom: 20px;
    }

    .content-container .info-text p {
        font-size: 16px;
        letter-spacing: 1.6px;
        color: #333333;
    }

    .offset-logo {
        margin-left: 8.33333333333%;
    }

    /*--------------------------------------------------------------
  # Philosophy
--------------------------------------------------------------*/
    .philosophy .banner-container-inner .banner-title {
        position: absolute;
        top: 76%;
        left: 0;
        z-index: 2;
        width: 100%;
        transform: translate(0px, 0px);
        padding-left: 25px;
    }

    .content-container .info-text {
        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
    }

    /*--------------------------------------------------------------
  # Service
--------------------------------------------------------------*/
    .service-container .row {
        justify-content: center;
        align-items: center;
    }

    .service-container .service-span {
        font: normal normal normal 90px/150px Melinda Script;
        letter-spacing: -9px;
        text-align: left;
        color: #999999;
        opacity: 1;
        position: relative;
        top: 20px;
        display: inline-block;
        width: 45px;
    }

    .service-container .info-text p {
        width: 100%;
    }

    .service-container .service-list {
        list-style: none;
        margin-left: 13px;
    }

    .service-container .service-list li {
        font: normal normal 100 16px/24px Helvetica Neue;
        letter-spacing: 0.8px;
        color: #333333;
    }

    .service-container .info-text.service-even {
        left: 0;
    }

    .service-pricing-container img {
        width: 100% !important;
    }

    .service-container img {
        margin-bottom: 10px;
    }

    /*--------------------------------------------------------------
  # Pricing
--------------------------------------------------------------*/
    .price-container .title {
        padding-top: 50px;
    }

    .pricing-title {
        position: inherit;
        top: 0;
        left: 0;
        transform: none;
        padding: 64px 76px;
        /* text-align: center; */
    }

    .pricing-title h2 {
        font-size: 24px;
        letter-spacing: 1.2px;
    }

    .price-container .price-box {
        margin-bottom: 10px;
    }

    .price-container .service-span {
        font: normal normal normal 90px/150px Melinda Script;
        letter-spacing: -9px;
        text-align: left;
        color: #999999;
        opacity: 1;
        position: relative;
        top: -50px;
        display: inline-block;
        width: 45px;
    }

    .price-container .info-text {
        padding: 50px 20px;
    }

    .price-container .service-list {
        margin-left: 0;
    }

    /*--------------------------------------------------------------
  # Portfolio
--------------------------------------------------------------*/
    .portfolio-section {
        padding-bottom: 40px !important;
    }

    .portfolio-section .title {
        padding-top: 0px !important;
    }

    .portfolio-container .portfolio-col {
        margin-bottom: 10px;
    }

    .portfolio-container .overlay {
        position: absolute;
        bottom: 0;
        left: 3.4%;
        right: 0;
        background: transparent
            linear-gradient(360deg, #000000 0%, #2b2b2b 58%, #54545400 100%) 0%
            0% no-repeat padding-box;
        overflow: hidden;
        width: 93.3%;
        height: 100px;
        transition: 0.5s ease;
    }

    /*--------------------------------------------------------------
  # Portfolio Residential / Commercial
--------------------------------------------------------------*/

    /*--------------------------------------------------------------
  # Contact Us
--------------------------------------------------------------*/
    .contact-info h3 {
        font-size: 18px;
        letter-spacing: 2.4px;
        color: #333333;
    }

    .contact-info p {
        font-size: 16px !important;
        letter-spacing: 0.4px;
    }

    .contact-info {
        display: contents;
    }

    .contact-container form {
        margin-left: 0 !important;
    }

    .contact-container form label {
        font-size: 16px;
    }

    .contact-container .form-group .form-control {
        height: 45px;
    }

    .submit-btn {
        margin-bottom: 50px;
    }

    .g-recaptcha {
        transform: scale(0.8) !important;
    }

    /*--------------------------------------------------------------
  # Book Now
--------------------------------------------------------------*/
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .offset-logo {
        margin-left: 0%;
    }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }

    .navbar-mobile ul {
        display: inline-grid;
        position: absolute;
        top: 55px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        padding: 10px 0;
        background-color: #fff;
        overflow-y: auto;
        transition: 0.3s;
        text-align: center;
        align-content: center;
        justify-content: center;
    }

    .offset-logo {
        margin-left: 0%;
    }
    .offset-logo {
        margin-left: 0%;
    }
}
